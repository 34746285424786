
.transitive-underline {
    position: relative;
  }
  
  .transitive-underline::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background-color: #bc9a64; 
    border-radius: 10px;
    transform: scaleX(0);
    transform-origin: bottom left;
    transition: transform 0.6s ease-in-out; 
  }
  
  .transitive-underline:hover::after {
    transform: scaleX(1);
    transform-origin: bottom right; 
  }