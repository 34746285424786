@tailwind base;
@tailwind component;
@tailwind utilities;

*{
  --dropdown-bg: #424242;
}

::-webkit-scrollbar{
  width: 6px;

}
::-webkit-scrollbar-track{
  background-color: #828282;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb{
  background-color: #bd9960;
  border-radius: 10px;
}

body {
  margin: 0;
  font-family: 'Sofia Sans',sans-serif;
  background-image: url('./Images/BgElement.png');
  background-size: 3% auto;
  background-attachment: fixed ;
}

@media (max-width: 1000px) {
  body{
    background-size:7% auto;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
